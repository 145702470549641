:root { 
--blue_gray_100:#d1d1d1; 
--black_900:#000000; 
--gray_800:#444444; 
--purple_A700:#9e00ff; 
--white_A700_68:#ffffff68; 
--gray_700_7f:#5f5f5f7f; 
--purple_A400:#f80de0; 
--gray_900_9e:#2525259e; 
--black_900_14:#00000014; 
--white_A700:#ffffff; 
--purple_A700_01:#ae02f9; 
--white_A700_82:#ffffff82; 
}